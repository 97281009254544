<template>
  <section class="mechanism-wrapper">
    <card title="线下销售额统计（分医院）">
      <div class="data-pick-wrapper">
        <span>选择日期: </span>
        <el-date-picker
          v-model="offline.date"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          @change="changeMonth(0)"
          format="yyyy 年 MM 月"
          value-format="yyyy/MM/dd"
        >
        </el-date-picker>
      </div>
      <div id="offline" class="charts"></div>
    </card>
    <card title="线上销售额统计（分产品数量）">
      <div class="data-pick-wrapper">
        <span>选择日期:</span>
        <el-date-picker
          v-model="online.date"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          @change="changeMonth(1)"
          format="yyyy 年 MM 月"
          value-format="yyyy/MM/dd"
        >
        </el-date-picker>
      </div>
      <div id="online" class="charts"></div>
    </card>
  </section>
</template>

<script>
import card from "../../components/card";
export default {
  components: {
    card
  },
  data() {
    return {
      online: {
        date: [],
        keys: [],
        data: [],
        url: "/getOnLine"
      },
      offline: {
        date: [],
        keys: [],
        data: [],
        url: "/getOffLine"
      },
      userRatioData: []
    };
  },
  methods: {
    offlineEcharts() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("offline"));

      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          left: "right",
          data: this.offline.keys
        },
        series: [
          {
            name: "销售额",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: this.offline.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    onlineEcharts() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("online"));

      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: this.online.keys
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: this.online.data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    getData(obj, init) {
      this.$axios({
        url: obj.url,
        params: {
          startDate: obj.date[0],
          endDate: obj.date[1]
        }
      }).then(res => {
        if (res.data.code == 200) {
          const keyMap = [];
          if (obj.url === "/getOnLine") {
            obj.data = res.data.data.map(item => {
              keyMap.push(item.productName);
              return {
                value: item.count,
                name: item.productName
              };
            });
          } else {
            obj.data = res.data.data.map(item => {
              keyMap.push(item.hospital);
              return {
                value: item.value,
                name: item.hospital
              };
            });
          }
          obj.keys = keyMap;
          this.$nextTick(() => {
            init();
          });
        } else {
          this.$message({
            type: "error",
            message: "销售额数据获取失败"
          });
        }
      });
    },
    changeMonth(type) {
      let date = new Date(
        type === 0 ? this.offline.date[1] : this.online.date[1]
      );
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let day = new Date(year, month, 0);
      let endTime = year + "/" + month + "/" + day.getDate();
      if (type === 0) {
        this.offline.date[1] = endTime;
        this.getData(this.offline, this.offlineEcharts);
      } else {
        this.online.date[1] = endTime;
        this.getData(this.online, this.onlineEcharts);
      }
    },
    dateInit() {
      const date = new Date();
      const nowMonth = date.getFullYear() + "/" + (date.getMonth() + 1) + "/";
      this.online.date = [nowMonth + "01", nowMonth + "28"];
      this.offline.date = [nowMonth + "01", nowMonth + "28"];
    }
  },
  created() {
    this.$openLoading();
    this.dateInit();
  },
  mounted() {
    Promise.all([
      this.getData(this.online, this.onlineEcharts),
      this.getData(this.offline, this.offlineEcharts)
    ]).then(res => {
      setTimeout(() => {
        this.$openLoading().close();
      }, 200);
    });
  }
};
</script>

<style lang="less">
.charts {
  width: 600px;
  height: 400px;
  margin: 0 auto;
}
</style>
